import { useReducer } from 'react';
import AppState, { AppStateInternal } from './AppState';
import { AppStateReducer } from './AppStateReducer';

const initialState: AppStateInternal = {
    isSidebarVisible: false
}

export default function UseAppStateManager(): AppState {
    const [{ isSidebarVisible }, dispatch] = useReducer(AppStateReducer, initialState);

    function toggleSidebarVisibility() {
        dispatch({ type: 'SetSidebarVisible', isSidebarVisible: !isSidebarVisible });
    }

    return { isSidebarVisible, toggleSidebarVisibility };
}