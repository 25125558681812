import React, { FunctionComponent } from "react";
import { Col, Container, Row } from "reactstrap";
import { Listing } from "../../APIWrapper";
import ListingView from "../ListingView/ListingView";

type StoreProps = {
    listings: Listing[],
    onListingDelete: (listingId: string) => void,
    onListingAddToBag: (listingId: string) => void
}

const Store: FunctionComponent<StoreProps> = ({ listings, onListingDelete, onListingAddToBag }) => {
    return (
        <Container>
          {listings?.map((listing) => (
            <Row>
              <Col md={{ size: 6, offset: 3 }}>
                <ListingView key={listing.id} {...listing} 
                  images={listing.images || []} 
                  onListingDelete={onListingDelete}
                  onListingAddToBag={onListingAddToBag} />
              </Col>
            </Row>
          ))}
        </Container>
    )
}

export default Store;