import React, { useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import './App.scss';
import awsconfig from './aws-exports';
import Amplify from 'aws-amplify';
import * as api from './APIWrapper';
import "./styles.scss";
import Sidebar from './components/Sidebar/Sidebar';
import MainPanel from './components/MainPanel/MainPanel';
import AppStateProvider from './state/AppStateContext';
import WebpageWrapper from './components/WebpageWrapper/WebpageWrapper';
import Store from './components/Store/Store';
import Home from './components/Home/Home';
import ReferralPage from './components/Referrals/ReferralPage/ReferralPage';

Amplify.configure(awsconfig);

const catListings: api.Listing[] = [
  { id: "id0", name: "Luna", description: "Her Highness Princess Luna Nox Fleuret", price: 0, images: [ "./img0.jpg" ]},
  { id: "id1", name: "Fukumaru", description: "Cute cat for your viewing pleasure.", price: 0, images: [ "./img1.jpg" ]},
  { id: "id2", name: "Chomusuke", description: "Cute cat for your viewing pleasure.", price: 0, images: [ "./img2.jpg" ]},
  { id: "id3", name: "Senpai", description: "Cute cat for your viewing pleasure.", price: 0, images: [ "./img3.jpg" ]},
  { id: "id4", name: "Alvdal", description: "Cute cat for your viewing pleasure.", price: 0, images: [ "./img4.jpg" ]},
]

const demoListings: api.Listing[] = [
  { id: "id1", name: "Giraffe", description: "A dreamy partner for your precious little one.", price: 99.99, images: [ "./canvas1.jpg" ]},
  { id: "id2", name: "Penguin", description: "A dreamy partner for your precious little one.", price: 99.99, images: [ "./canvas2.jpg" ]},
  { id: "id3", name: "Lion",    description: "A dreamy partner for your precious little one.", price: 99.99, images: [ "./canvas3.jpg" ]},
  { id: "id4", name: "Fox",     description: "A dreamy partner for your precious little one.", price: 99.99, images: [ "./canvas4.jpg" ]},
  { id: "id5", name: "Stork",   description: "A dreamy partner for your precious little one.", price: 99.99, images: [ "./canvas5.jpg" ]},
]

function App() {

  const [listings, setListings] = useState<api.Listing[]>([]);

  const getData = async() => {
    setListings(demoListings);
    // try {
    //   const receivedListings = await api.getListings();
    //   setListings(receivedListings);
    // } catch (error) {
    //   console.error("Error fetching listings", error);
    // }
  }


  const handleListingDelete = async(id: string) => {
    try {
      await api.deleteListing({ id: id });
      await getData();
    } catch (error) {
      console.error(error);
    }
  }

  const handleListingAddToBag = (id: string) => {
    const listing = listings?.find(l => l.id === id);
    console.log(`Listing ${listing?.name} added to bag!`);
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <BrowserRouter>
      <AppStateProvider>
        <Switch>
          <Route path="/referrals" exact>
            <ReferralPage />
          </Route>
          <Route>
            <WebpageWrapper>
              <Sidebar />
              <MainPanel>
                <Switch>
                  <Route path="/" exact>
                    <Home images={catListings.map(listing => listing.images ? listing.images[0] : "")} />
                  </Route>
                  <Route path="/store">
                    <Store listings={demoListings} 
                      onListingAddToBag={handleListingAddToBag} 
                      onListingDelete={handleListingDelete} />
                  </Route>
                  <Route path="/cats">
                    <Store listings={catListings} 
                      onListingAddToBag={handleListingAddToBag} 
                      onListingDelete={handleListingDelete} />
                  </Route>
                </Switch>
              </MainPanel>
            </WebpageWrapper>
          </Route>
        </Switch>
      </AppStateProvider>
    </BrowserRouter>
  );
}

export default App;