import React, { useContext } from "react";
import { FunctionComponent } from "react";
import { AppStateContext } from "../../state/AppStateContext";

const WebpageWrapper: FunctionComponent = ({ children }) => {

    const { isSidebarVisible } = useContext(AppStateContext)!;

    return (
        <div className={ "wrapper " + (isSidebarVisible ? "nav-open" : "" ) }>
            { children }
        </div>
    )
}

export default WebpageWrapper;