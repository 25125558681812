import React, { FunctionComponent } from "react";
import { Card, CardHeader, CardBody, Button, Container, Col, Row } from "reactstrap";

type HomeProps = {
    /** Images to show in the slideshow */
    images: string[]
}

const deliveryDate = new Date(0, 11, 18);

const wishes = [
    '-------------------',
    'За моята слънчева Ади,',
    'С пожелания за много щастие,',
    'и всичката любов на света.',
    'Пожелавам незабравими моменти,',
    'забавление в личните експерименти,',
    'и успех при достигане на целта.',
    'Честит рожден ден!',
    '-------------------',
    'С обич, Ивайло и Принцеса Луна'
];

const Home: FunctionComponent<HomeProps> = ({ images }) => {

    function isDeliveryDay() {
        const currentDate = new Date();
        return currentDate.getDate() === deliveryDate.getDate() 
            && currentDate.getMonth() === deliveryDate.getMonth();
    }
    
    return (
        <React.Fragment>
            <Container>
                <Row>
                    <Col lg={{ size: 8, offset: 2 }}>
                        <Card className="bg-transparent border-0 shadow-none">
                            <CardHeader>
                                <h5 className="text-center">Welcome to Sinensis Art</h5>
                            </CardHeader>
                            <CardBody>
                                <p className="text-center">A personal project aiming to bring high-quality digital self-printable art to your home</p>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                { isDeliveryDay() ? 
                <Row>
                    <Col lg={{ size: 8, offset: 2 }}>
                        <Card>
                            <CardBody>
                                <h4 className="text-center">Честит Рожден Ден</h4>
                                {wishes.map(w => 
                                    <p className="text-center">{w}</p>
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row> : <></> 
                }
                <Row>
                    <Col lg={{ size: 8, offset: 2 }}>
                        <Card>
                            <CardBody>
                                <img src={images[0]} className="img-fluid" alt="Listing" /> 
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}

export default Home;