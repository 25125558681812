import React from "react";
import { Card, CardHeader, CardBody } from "reactstrap";

export interface ReferralTileProps {
    name: string;
    code: string;
    instructions: string;
    image?: string;
}

export default function ReferralTile(props: ReferralTileProps): React.ReactElement<ReferralTileProps> {
    return (
        <Card>
            <CardHeader>
                <h2 className="card-title">{props.name}</h2>
            </CardHeader>
            <CardBody>
                <p className="card-category">{props.instructions}</p>
                <h4>{props.code}</h4>
            </CardBody>
        </Card>
    )
}