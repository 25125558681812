import { AppStateInternal } from "./AppState";

export type AppStateAction =
    | { type: 'SetSidebarVisible', isSidebarVisible: boolean }

export function AppStateReducer(state: AppStateInternal, action: AppStateAction): AppStateInternal {
    switch(action.type) {
        case 'SetSidebarVisible':
            return { ...state, isSidebarVisible: action.isSidebarVisible };
        default:
            return state;
    }
}