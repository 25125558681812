import React from "react";
import { Container, Row, Col } from "reactstrap";
import ReferralTile from "../ReferralTile/ReferralTile";
import styles from "./ReferralPage.module.scss";

type Referral = string;

interface ReferralGroup {
  companyName: string;
  instructions: string;
  codes: Referral[];
}

const referrals: ReferralGroup[] = [
  {
    companyName: "Fizz",
    instructions: "Instructions on how to use fizz referral + links.",
    codes: [ "SG69T", "GF9NQ", "DPKC1", "XMM3S" ]
  },
  {
    companyName: "Tangerine",
    instructions: "Instructions on how to use tangerine referral + links.",
    codes: [ "52455903S1", "54293730S1", "62172380S1" ]
  },
]

function getRandomCode(codes: Referral[]): Referral {
  const randIdx = Math.floor(Math.random() * codes.length);
  return codes[randIdx];
}

export default function ReferralPage(): React.ReactElement {
    return (
        <div className={styles.page}>
        <Container>
          {referrals.map((referral) => (
            <Row>
              <Col md={{ size: 8, offset: 2 }}>
                <ReferralTile name={referral.companyName} instructions={referral.instructions} code={getRandomCode(referral.codes)} />
              </Col>
            </Row>
          ))}
        </Container>
        </div>
    )
}