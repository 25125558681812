import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { CreateListingMutation, CreateListingMutationVariables, DeleteListingMutation, DeleteListingMutationVariables, ListListingsQuery } from "./API";
import * as graphQLMutations from "./graphql/mutations";
import * as graphQLQueries from "./graphql/queries";

/** ----- GraphQL helper functions ----- */

export interface GraphQLOptions {
    input?: object;
    variables?: object;
    authMode?: GRAPHQL_AUTH_MODE;
}

async function callGraphQL<T>(query: any, options?: GraphQLOptions): Promise<GraphQLResult<T>> {
    let response = (await API.graphql(graphqlOperation(query, options))) as GraphQLResult<T>;
    return response;
}

/** ----- Wrap endpoints logic ----- */

// Models
export interface Listing {
    id: string;
    name: string;
    price: number;
    description?: string;
    images?: string[];
}

export interface ListingCreationData {
    name: string;
    price: number;
    description?: string;
    images?: string[];
}

export interface ListingDeleteData {
    id: string
}

// Mappers
function mapListListingsQuery(listListingsQuery: GraphQLResult<ListListingsQuery>): Listing[] {
    return listListingsQuery.data?.listListings?.items?.map(listing => ({
        id: listing?.id,
        name: listing?.name,
        price: listing?.price,
        description: listing?.description,
        images: listing?.images
    } as Listing)) || [];
}

function mapCreateListing(listing: ListingCreationData): CreateListingMutationVariables {
    return {
        input: {
            name: listing.name,
            price: listing.price,
            description: listing.description,
            images: listing.images ? [...listing.images] : listing.images,
        }
    }
}

function mapDeleteListing(listing: ListingDeleteData): DeleteListingMutationVariables {
    if(!listing) {
        throw new Error('Invalid listing argument');
    }

    return {
        input: {
            id: listing.id
        }
    }
}

// Exported functions
export async function getListings(): Promise<Listing[]> {
    const listingsData = await callGraphQL<ListListingsQuery>(graphQLQueries.listListings);
    const listings = mapListListingsQuery(listingsData);
    return listings;
}

export async function createListing(listing: ListingCreationData): Promise<void> {
    const createListingMutationVariables = mapCreateListing(listing);
    await callGraphQL<CreateListingMutation>(graphQLMutations.createListing, createListingMutationVariables);
}

export async function deleteListing(listing: ListingDeleteData): Promise<void> {
    const deleteListingMutationVariables = mapDeleteListing(listing);
    await callGraphQL<DeleteListingMutation>(graphQLMutations.deleteListing, deleteListingMutationVariables);
}