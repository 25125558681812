import React, { FunctionComponent, useContext, useState } from "react";
import { Button, Collapse, Form, Input, NavLink } from "reactstrap";
import { AppStateContext } from "../../state/AppStateContext";

type NavbarProps = {
    title: string,
    onSidebarVisibilityToggled: (isVisible: boolean) => void
}

const Navbar: FunctionComponent<NavbarProps> = ({ title, onSidebarVisibilityToggled }) => {

    const { isSidebarVisible, toggleSidebarVisibility } = useContext(AppStateContext)!;

    const [isMenuCollapsed, setMenuCollapsed] = useState(true);

    const handleMenuButtonClick = () => {
        setMenuCollapsed(!isMenuCollapsed);
    }

    return (
        <nav className={ "navbar navbar-expand-lg navbar-absolute fixed-top " + 
                            ( isMenuCollapsed ? "navbar-transparent" : "bg-white") } >
            <div className="container-fluid">
                <div className="navbar-wrapper">
                    <div className={ "navbar-toggle " + (isSidebarVisible ? "toggled" : "") }>
                        <Button type="button" className="navbar-toggler"
                                onClick={toggleSidebarVisibility}>
                            <span className="navbar-toggler-bar bar1"></span>
                            <span className="navbar-toggler-bar bar2"></span>
                            <span className="navbar-toggler-bar bar3"></span>
                        </Button>
                    </div>
                    <NavLink to="/" className="navbar-brand">{title}</NavLink>
                </div>
                <Button className="navbar-toggler" type="button" onClick={handleMenuButtonClick}
                    aria-expanded={!isMenuCollapsed} aria-controls="collapseExample">
                    <span className="navbar-toggler-bar navbar-kebab"></span>
                    <span className="navbar-toggler-bar navbar-kebab"></span>
                    <span className="navbar-toggler-bar navbar-kebab"></span>
                </Button>
                <Collapse className="collapse navbar-collapse justify-content-end" id="collapseExample" isOpen={!isMenuCollapsed}>
                    <Form>
                        <div className="input-group no-border">
                            <Input type="text" value="" className="form-control" placeholder="Search...">
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <i className="nc-icon nc-zoom-split"></i>
                                    </div>
                                </div>
                            </Input>
                        </div>
                    </Form>
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <NavLink to="/" className="nav-link btn-rotate">
                                <i className="nc-icon nc-settings-gear-65"></i>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/" className="nav-link btn-rotate">
                                <i className="nc-icon nc-bag-16"></i>
                            </NavLink>
                        </li>
                    </ul>
                </Collapse>
            </div>
        </nav>
    )
}

export default Navbar;