import React, { FunctionComponent } from "react"
import AppState from "./AppState";
import UseAppStateManager from "./AppStateManager";

export const AppStateContext = React.createContext<AppState | undefined>(undefined);

const AppStateProvider: FunctionComponent = ({ children }) => {

    const appState = UseAppStateManager();

    return (
        <AppStateContext.Provider value={appState}>
            { children }
        </AppStateContext.Provider>

    )
}

export default AppStateProvider;