import React, { FunctionComponent, useContext } from "react";
import { AppStateContext } from "../../state/AppStateContext";
import Navbar from "../Navbar/Navbar";

const MainPanel: FunctionComponent = 
    ({ children }) => {

    const { isSidebarVisible, toggleSidebarVisibility } = useContext(AppStateContext)!;

    const handleSidebarVisibilityToggled = () => {
      toggleSidebarVisibility();
    }

    return (
      <div className="main-panel" style={{ width: (isSidebarVisible ? 'fixed' : undefined) }}>
        <Navbar title="Sinensis Art" onSidebarVisibilityToggled={handleSidebarVisibilityToggled}></Navbar>
        <div className="content">
          { children }
        </div>
      </div>
    )
}

export default MainPanel;