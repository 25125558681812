import React, { FunctionComponent, useContext } from "react";
import { NavLink } from "react-router-dom";
import { AppStateContext } from "../../state/AppStateContext";

interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}

const routes: RouteInfo[] = [
    { path: '/',            title: 'Home',      icon:'nc-shop',        class: '' },
    { path: '/store',       title: 'Store',     icon:'nc-album-2',     class: '' },
    { path: '/cats',        title: 'Cats',      icon:'nc-box',     class: '' },
    { path: '/account',     title: 'Account',   icon:'nc-single-02',   class: '' },
    { path: '/about',       title: 'About',     icon:'nc-badge',       class: '' },
]

const Sidebar: FunctionComponent = () => {

    const { isSidebarVisible, toggleSidebarVisibility } = useContext(AppStateContext)!;

    function handleNavLinkClick() {
        if (isSidebarVisible) {
            toggleSidebarVisibility()
        }
    }

    return (
        <div className="sidebar" data-color="white" data-active-color="danger">
            <div className="sidebar-wrapper">
                <div className="logo">
                    <NavLink className="simple-text logo-mini" to="/" onClick={handleNavLinkClick}>
                        <div className="logo-image-small">
                            <i className="nc-icon nc-shop"></i>
                        </div>
                    </NavLink>
                </div>
                <ul className="nav"> 
                {
                    routes.map(element =>
                        <li className={ `${element.class}` }> 
                            <NavLink to={ `${element.path}` } onClick={handleNavLinkClick}>
                                <i className={ "nc-icon " + element.icon }></i>
                                <p>{ element.title }</p>
                            </NavLink>
                        </li>
                    )
                }
                </ul>
            </div>
        </div>
    )
}

export default Sidebar;