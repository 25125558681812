import React, { FunctionComponent } from 'react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';

export type ListingBoxProps = {
    /** The id of the listing is kept for convenience to pass in the callbacks. */
    id: string,
    name: string,
    price: number,
    description?: string,
    images: string[],
    onListingDelete: (listingId: string) => void,
    onListingAddToBag: (listingId: string) => void
}

function formatCurrency(amount: number): string {
    if (!amount) {
        amount = 0.0;
    }
    return amount.toLocaleString('en-US', { style: 'currency', currency: 'CAD' });
}

const ListingView: FunctionComponent<ListingBoxProps> = 
    ({ id, name, price, description, images, onListingAddToBag }) => {

        return (
            <Card>
                <CardHeader>
                    <h5 className="card-title">{name}</h5>
                </CardHeader>
                <CardBody>
                    <img src={images[0]} className="img-fluid" alt="Listing" /> 
                    <p className="card-category">{description}</p>
                    <div className="d-flex align-">
                        <p>{formatCurrency(price)}</p>
                        <Button className="ml-auto" 
                            onClick={() => onListingAddToBag(id)}>Add to bag</Button>
                    </div>
                </CardBody>
            </Card>
        )
    }

export default ListingView;